import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import SEO from "../components/SEO";
import styled from '@emotion/styled';
import { Styled } from 'theme-ui';
import { Link } from "gatsby";
export const HomeCenteredArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const HomeHeadline = styled.h1`
      color: ${props => props.theme.colors.darken};
      font-weight: ${props => props.theme.fontWeights.fattest};
      font-size: ${props => props.theme.tachyons.size.f1};
      letter-spacing: ${props => props.theme.letterSpacings.heading};
      display: block;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
      line-height: 1.125;
        @media all and (max-width: 800px) {
          font-size: 49px;
		}
	}
`;
export const Accent = styled.span`
      color: ${props => props.theme.colors.secondary};
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Body = makeShortcode("Body");
const Avatar = makeShortcode("Avatar");
const layoutProps = {
  HomeCenteredArea,
  HomeHeadline,
  Accent,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <SEO mdxType="SEO" />
    <Body mdxType="Body">
      <HomeCenteredArea mdxType="HomeCenteredArea">
        <Avatar mdxType="Avatar" />  
  <HomeHeadline mdxType="HomeHeadline">
    Hi, I'm Slyvon Blanco<Accent mdxType="Accent">.</Accent>
  </HomeHeadline>
        <p>{`I'm an independent web developer, designer, consultant, and chicken wings addict. I wear a lot of hats, but I spend most of my time `}<a parentName="p" {...{
            "href": "https://aestheticpixels.io",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`making websites`}</a>{` for clients or working on side projects like `}<a parentName="p" {...{
            "href": "https://simplifiedfitness.io",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`SimplifiedFitness.io`}</a>{`. `}</p>
        <p>{`IRL, you can probably find me wandering around aimlessly... at a gym lifting heavy things... or at home rewatching a Christopher Nolan movie.`}</p>
        <p>{`Welcome to my little corner of the internet.`}</p>
        <p><a parentName="p" {...{
            "href": "/about"
          }}>{`Read more →`}</a></p>
      </HomeCenteredArea>
    </Body>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      